body {
  background-color: #f5f6f8 !important;
}
.fz-16 {
  font-size: 16px;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}
.iframe-height {
  height: calc(100vh - 75px);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
.cursor-pointer {
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.access-key-container button.copy-button {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
textarea.access-key {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: #202020;
  color: #fff;
  border-radius: 4px;
  min-height: 150px;
}
.appmaker-logo {
  width: 166px;
}
.step-box::before {
  content: '';
  position: absolute;
  top: -15px;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0);
}
.dashed {
  border: rgb(206, 212, 218) dashed 1px !important;
}
.seperation-line {
  position: relative;
}
.seperation-line::before {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  top: 50%;
  z-index: 0;
  border-bottom: 1px solid rgb(148, 148, 148);
}
.seperation-line span {
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 0 10px;
}
/* Step 1 */

.step-box-1::before {
  left: 40px;
}
.nav-bullets {
  height: 90px !important;
  position: relative;
  padding: 32px 0 60px 0;
  left: 43px;
}
.nav-bullets li a {
  width: 40px;
  display: inline-block;
}
.nav-bullets li a::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background: #4b6efc;
  border-radius: 50%;
  opacity: 0.3;
}
.nav-bullets li.active a::after {
  opacity: 1;
}
.nav-bullets li.active-step2 a::after {
  opacity: 1;
}

/* Choose Method */
.step-box-chooseMethod::before {
  left: 80px;
}
.choose-method a.dashed-container {
  border: rgb(206, 212, 218) dashed 1px;
  border-radius: 5px;
  position: relative;
}
.choose-method a.dashed-container:hover {
  border: #4b6efc solid 1px;
  background: #f5f6f8;
}
.choose-method .learnmore {
  position: absolute;
  right: 25px;
  bottom: 20px;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #31454e transparent !important;
  border-width: 0 0.5rem 0.4rem 0.5rem !important;
}
/* Book demo */
.react-tel-input input {
  width: 100% !important;
}
.custom-checkbox,
.custom-radio {
  border: 1px solid #dee2e6;
}
.custom-checkbox input:checked + label {
  color: #4b6efc !important;
}
.custom-radio input:checked + label {
  color: #4b6efc !important;
}

/* Step-2 appname and logo */

.step-box-2::before {
  left: 120px;
}
.plus-icon {
  position: absolute;
  color: rgb(206, 212, 218);
  right: 30%;
  top: 30%;
  text-align: center;
}
.plus-icon i {
  font-size: 2rem;
}

/* thank you page */
.step-box-thank::before {
  left: 160px;
}

/* plugin check page */
.step-box-plugin {
  min-height: 340px;
}
.step-box-plugin .row {
  min-height: inherit;
}
.step-box-plugin::before {
  left: 160px;
}
.step-box-plugin .progress {
  height: 0.5rem;
}
.plugin-setup-step h6 {
  position: relative;
  margin-left: 35px;
}
.plugin-setup-step h6::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #212121;
  border-radius: 20px;
  top: 3px;
  left: -25px;
}
.plugin-setup-step-valid h6::before {
  position: absolute;
  content: '✔';
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  background-color: #4b6efcc2;
  border-radius: 20px;
  left: -30px;
  top: -2px;
}
/* plugin status component */
.watch-video button:focus {
  outline: 0 !important;
}
.plugin-status {
  padding: 1.5rem;
  border-radius: 4px;
  color: #fff;
}
.check-button button {
  background: #0000003b;
  padding: 5px 25px;
  border-radius: 50px;
  border: none;
}
.check-button button:focus {
  outline: 0;
}
.check-button button:hover {
  opacity: 0.8;
  box-shadow: #0000003b;
}

.support-button button {
  background: #fff;
  padding: 5px 20px;
  border-radius: 50px;
  border: none;
}
.support-button button:focus {
  outline: 0;
}
.support-button button:hover {
  opacity: 0.8;
  box-shadow: #0000003b;
}
.plugin-status-initial {
  background: #567685;
}
.plugin-status-danger {
  background: #ff7e81;
}
.plugin-status-warning {
  background: #ffc961;
}
.plugin-status-success {
  background: #4ff87c;
}
.modal-dialog {
  max-width: 800px !important;
  margin: 30px auto !important;
}
.modal-content {
  border: none !important;
}
.modal-body {
  position: relative !important;
  padding: 0px !important;
}

/* dashboard */
.template-preview {
  width: 170px;
}
.app-template-container .phone-case {
  width: 165px;
  height: 310px;
  position: relative;
  background-image: url(https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/07/f9c58a9b-phone-frame.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  pointer-events: none;
}
.design-container {
  width: 155px;
  height: 290px;
  top: 9px;
  left: 9px;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  cursor: pointer;
}
.design-container .template-design {
  position: absolute;
  z-index: 1;
}
/* .template-phone-frame {
  position: absolute;
} */
.large-modal {
  min-width: 80%;
}
.disable-effect:focus,
.disable-effect:active {
  outline: none !important;
  box-shadow: none !important;
}
.app-name {
  max-width: 150px;
  display: block;
}
.navbar-top .nav-link {
  line-height: 50px !important;
}
.navbar-vertical {
  background-color: #f5f6f8;
}
.navbar-vertical a.active {
  background-color: rgba(255, 255, 255, 0.747);
}
.navbar-vertical a.active .cls-1,
.navbar-vertical a.active .cls-1-1,
.navbar-vertical a.active .cls-1-2,
.navbar-vertical a.active .cls-2-1,
.navbar-vertical a.active .cls-4-1,
.navbar-vertical a.active .cls-3,
.navbar-vertical a.active .cls-3-5,
.navbar-vertical a.active .cls-5-1 {
  fill: #ff373b;
}
.navbar-vertical a.active .cls-7-6,
.navbar-vertical a.active .cls-7-4 g *,
.navbar-vertical a.active .customer-data-icon {
  stroke: #ff373b !important;
}
.expanding-menu.collapsed .cls-3-5 {
  fill: #212121;
}
.expanding-menu .cls-3-5 {
  fill: #ff373b;
}
.expanding-menu.collapsed .cls-7-5 {
  stroke: #212121 !important;
}
.expanding-menu .cls-7-5 {
  stroke: #ff373b !important;
}
.expanding-menu.collapsed {
  background-color: rgba(255, 255, 255, 0);
}
.expanding-menu {
  background-color: rgba(255, 255, 255, 0.747);
}
.expanding-menu.collapsed .fa-chevron-down {
  transform: rotate(0deg);
}
.expanding-menu .fa-chevron-down {
  transform: rotate(180deg);
  transition: all 100ms ease-in-out;
}
.nav-fix-bottom {
  position: fixed;
  bottom: 1rem;
  display: block;
}
.back-nav-hover:hover {
  color: #ff373b !important;
}
.vertical-nav-hover:hover {
  background-color: rgba(0, 0, 0, 0.089);
}
.main-nav {
  max-height: calc(100vh - 175px);
  overflow: auto;
}
.dashboard-box {
  border: 1px solid #e1ebff;
}
.dashboard-main {
  overflow-y: scroll;
}
.fixed-left {
  left: 0;
}
nav.navbar-vertical {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 250px;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 15px;
  display: block;
}
nav.navbar-vertical .navbar-brand::after {
  display: block;
  margin: 1rem -1rem;
  content: '';
}
nav.navbar-vertical .navbar-nav {
  display: block;
}

.border-dashed {
  border: 1px dashed;
}
.add-new-page {
  cursor: pointer;
  color: #31454e;
}
.add-new-page:hover {
  border: 1px solid;
  color: #31454e;
}
/* .drag-block-container {
  overflow-y: scroll;
} */
.drag-block-list {
  list-style: none;
  padding: 0.2rem;
  margin: 0;
}
.drag-item {
  border: 1px solid #e9e9e9;
  background: #f5f6f8;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.75em;
  cursor: grab;
  margin-bottom: 0.75rem;
  display: block;
}
.drag-item:active {
  cursor: grabbing;
}
.small-label-text {
  font-size: 12px;
  opacity: 0.8;
}
.stepper .input-group {
  width: 100px;
}
.custom-control-input::before,
.custom-control-input::after {
  cursor: pointer !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4b6efc !important;
  border: rgb(60, 88, 199) !important;
}
.wrap {
  height: calc(100vh - 57px);
}

.image-check label.radio-container {
  height: 150px;
  width: 150px;
  padding: 1rem;
  border: 1px solid #d1e0fc;
  background-color: #f5f6f8;
  border-radius: 8px;
  cursor: pointer;
}
.tick-container {
  display: none;
}
.image-check :checked + label .tick-container {
  position: absolute;
  display: block;
  background-color: #2bce67;
  color: #fff;
  width: 98px;
  padding: 3px 0;
  left: 0;
  bottom: 0;
  border-radius: 0 0 6px 6px;
}
.image-check :checked + label.radio-container {
  border: 1px solid #2bce67;
}
.react-date-picker__wrapper {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.dropdown-menu {
  z-index: 2000 !important;
}
.app-build-area .app-view {
  width: 400px;
  margin: 1rem auto 0 auto;
}
.app-name-customizer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-menubar {
  width: stretch;
}
.phone-bg {
  min-height: calc(100vh - 150px);
}
.drop-area-container {
  width: stretch;
  min-height: 430px;
}
.drop-area {
  border: 1px dashed #31454e8a;
  width: inherit;
  min-height: 100px;
  border-radius: 8px;
  background-color: #31454e09;
}
.plus-absolute {
  left: 25%;
  font-weight: bold;
}
.active-block {
  border: 3px solid black;
  position: relative;
  border-radius: 0 4px 4px 4px;
  padding: 0.25rem;
}
.active-block::before {
  content: '|||';
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  left: -30px;
  top: -3px;
  width: 30px;
  height: 20px;
  background: black;
  border-radius: 4px 0 0 4px;
  cursor: grab;
}
.active-block:active::before {
  cursor: grabbing;
}
.carousel-indicators li {
  width: 6px !important;
  height: 6px !important;
  margin-right: 3px !important;
  margin-left: 3px !important;
  border-radius: 50%;
}
.carousel-indicators li.active {
  background-color: #4b6efc !important;
  opacity: 1 !important;
}
.carousel-indicators {
  margin-bottom: 0 !important;
}
.image-scroller-container {
  overflow: auto;
  white-space: nowrap;
}
.image-scroller-container img {
  margin: 0 0.5rem 0.5rem 0;
}
.text-edit-toolbar {
  background-color: #000 !important;
  position: absolute;
  top: -43px;
  right: -3px;
  border: none !important;
  border-radius: 4px 4px 0 0 !important;
}
.text-type-area div {
  margin: 0;
}
.add-new-field::after {
  content: '+';
  color: #fff;
  font-size: 15px;
  text-align: center;
  position: absolute;
  right: -30px;
  bottom: -3px;
  width: 30px;
  height: 22px;
  background: black;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.editing-block {
  border: 2px solid rgba(60, 88, 199, 0.705);
}
.profile-pic img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: #31454e 0.8px solid;
}
.left-bar {
  position: relative;
  padding-left: 0.5rem;
}
.left-bar::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 40px;
  background-color: #4b6efc;
  left: 0;
  top: 0;
}
.product-scroller-container {
  overflow: auto;
  white-space: nowrap;
}
.product-card {
  width: 120px;
  display: inline-block;
}
.product-card h6 {
  font-weight: bold;
  margin: 10px 0 0 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.count-add-cart a {
  font-size: 10px;
  font-weight: bold;
}
.search-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
}
.select-img,
.select-img-loader {
  height: 142px;
  width: 142px;
}
.select-img label.image-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}
.select-img label.image-container img {
  width: 135px;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  object-fit: contain;
}
.select-icon label.icon-container {
  cursor: pointer;
}
label .tritick-container {
  display: none;
}
.select-img input:checked + label .tritick-container {
  display: block;
  color: rgb(255, 255, 255);
  position: absolute;
  background: #2bce67c7;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0.25rem;
  padding: 3px 6px;
  border-radius: 2px;
  opacity: 1;
}
.select-icon input:checked + label.icon-container .tritick-container {
  display: block;
  color: rgb(255, 255, 255);
  position: absolute;
  background: #2bce67c7;
  top: -2px;
  left: -2px;
  padding: 3px 6px;
  border-radius: 2px;
  opacity: 1;
}
.upload-new {
  width: 142px;
  height: 142px;
  border: 1px dashed #969696;
  color: #969696;
}

.page-type-select label.radio-container {
  height: 200px;
  width: 200px;
  padding: 1rem;
  border: 1px solid #d1e0fc;
  background-color: #f5f6f8;
  border-radius: 8px;
  cursor: pointer;
}
.page-type-select :checked + label .tick-container {
  position: absolute;
  display: block;
  background-color: #2bce67;
  color: #fff;
  width: 198px;
  padding: 3px 0;
  left: 0;
  bottom: 0;
  border-radius: 0 0 6px 6px;
}
.page-type-select :checked + label.radio-container {
  border: 1px solid #2bce67;
}
.tab-action-inner {
  background-color: #000;
  color: #fff;
}
.tab-action-inner .block-action label {
  color: #fff;
}
.tab-action-inner .block-action {
  color: #000;
}
.popover {
  z-index: 1045 !important;
}
.arrow::after {
  border-top-color: #000 !important;
}
.menu-drop-area-container {
  z-index: 500;
  width: 300px;
  box-shadow: 77px 0 #31454e46;
}
.top-tab-config-area {
  overflow-x: auto;
  overflow-y: visible;
}
.top-active-tab h6 {
  position: relative;
}
.top-active-tab h6::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #31454e;
  left: 0;
  top: 24px;
}
.menu-drop-area-container {
  min-height: 450px;
}
.settings-active-nav {
  color: #ff373b;
  position: relative;
  margin-left: 8px;
}
.settings-active-nav::before {
  position: absolute;
  content: '';
  width: 4px;
  height: 26px;
  left: -23px;
  top: -5px;
  background-color: #ff373b;
}

/* manage page */
.manage-active::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: red;
  left: 0;
  bottom: 0;
  z-index: 2;
}
.hover-item::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: black;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 180ms ease-out;
}
.hover-item:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.app-card {
  width: 31%;
  border-radius: 10px !important;
  background-color: #fff;
}
.card-menu {
  right: 10px;
  top: 10px;
  cursor: pointer;
  opacity: 0.6;
}
.card-menu:hover {
  opacity: 1;
}
.manage-app-button {
  background-color: #181818;
  position: relative;
  border-radius: 0 0 10px 10px;
}
.manage-app-button a {
  z-index: 2;
}
.manage-app-button:hover {
  background-color: #1a2031;
}
.manage-app-button::before {
  content: '';
  position: absolute;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 100%;
  background-color: rgb(56, 201, 128);
  left: 0;
  top: 0;
  opacity: 0.2;
  cursor: pointer;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 100ms ease-out;
}

.manage-app-button:active::before {
  transform: scaleY(1);
  transform-origin: top;
}
.modal-dialog {
  font-family: 'Montserrat', sans-serif;
}
.bg-black {
  background-color: #181818;
}
.subscription-data {
  height: calc(100vh - 110px);
}
.subscription-data table {
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.subscription-data table th,
.subscription-data table td {
  vertical-align: middle !important;
}

.subscription-data thead th {
  border: 0 !important;
}

.subscription-data tbody th,
.subscription-data tbody td {
  background-color: rgb(252, 252, 252);
  border-top: 1px solid #d9e6fa !important;
  border-bottom: 1px solid #d9e6fa !important;
}

.subscription-data tbody tr td:first-child,
.subscription-data tbody tr th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #d9e6fa !important;
}

.subscription-data tbody tr td:last-child,
.subscription-data tbody tr th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #d9e6fa !important;
}
.appupload-card {
  max-width: none !important;
  width: 380px !important;
}
.instruction-modal {
  min-height: calc(100vh - 3.5rem) !important;
}
.instruction-step {
  opacity: 0.2;
}
.active-instruction-step {
  opacity: 1;
}
.new-file-input {
  position: relative;
}
.new-file-input input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  zoom: 1; /* Fix for IE7 */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  cursor: pointer;
}
.new-file-input label.drop-zone {
  text-align: center;
  padding: 30px 0;
  font-weight: bold;
  border: 1px dashed #7066ff;
  width: 100%;
  border-radius: 8px;
  background-color: #d6ddfda9;
}
.off-badge {
  left: -10px;
  top: -10px;
  z-index: 2;
}
/* Loading */
.block-loading {
  position: relative;
  overflow: hidden;
  padding: 6rem;
  opacity: 0.7;
  border-radius: 8px;
  background-color: rgb(238, 238, 238);
}
.block-loading::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 60%;
  filter: blur(80px);
  -webkit-filter: blur(80px);
  background-color: rgb(190, 190, 190);
  opacity: 0.3;
  left: 0;
  top: 0;
  animation: loading 1s infinite;
}
.display-sm {
  display: none;
}
.nav-collapse-button {
  position: absolute;
  right: -10px;
  top: 10px;
  z-index: 10;
}
.main-container {
  margin-left: 250px;
}
.mobile-menu-toggle {
  display: none;
}
.subscription-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.subscription-card i.fa-receipt {
  position: absolute;
  font-size: 250px;
  transform: rotate(45deg);
  right: 0;
  top: -100px;
  opacity: 0.4;
  z-index: 0;
}
.sales-data-card button.btn {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.sales-data-card button.btn.active-button {
  background-color: rgba(255, 255, 255, 0.9);
  color: #4b6efc;
}
.sale-data-widget {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.sale-data-widget * {
  color: #fff;
}
.sale-data-widget p i.fa-arrow-up {
  transform: rotate(45deg);
}
.sale-data-widget p i.fa-arrow-down {
  transform: rotate(-45deg);
}
@keyframes loading {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

/* Invoice print */
@media print {
  body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: relative;
    left: 0;
    top: 0;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .app-card {
    width: 100%;
  }
  .dashboard-navbar ul {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .dashboard-navbar ul::-webkit-scrollbar {
    display: none;
  }
  .main-container {
    margin-left: unset;
  }
  .manage-dashboard {
    position: relative;
  }
  .navbar-vertical {
    position: relative;
    left: -250px;
    top: 0;
    z-index: 10;
    border-right: 1px solid rgb(202, 202, 202);
    transition: left 0.3s ease-in-out;
  }
  .display-sm {
    display: block;
  }
  .navbar-vertical.active {
    left: 0;
  }
  .mobile-menu-toggle {
    display: block;
    color: #fff;
    background-color: #212121;
    padding: 10px;
    position: absolute;
    left: -15px;
    border-radius: 0 8px 8px 0;
    top: 10px;
    z-index: 100;
    border: none;
    transition: left 0.3s ease-in-out;
  }
  .mobile-menu-toggle:active {
    outline: none;
  }
  .mobile-menu-toggle:focus,
  .mobile-menu-toggle:active {
    outline: none;
  }
  .mobile-menu-toggle.active {
    left: 235px;
    color: #fff;
    background-color: #212121;
    border-radius: 0 8px 8px 0;
    padding: 10px;
  }
  .subscription-message-ontopbar a {
    display: none;
  }
  .dashboard-box {
    margin-bottom: 10px;
  }
  .subscription-card i.fa-receipt {
    display: none;
  }
}
/* small  devices  (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .app-card {
    width: 100%;
  }
  .dashboard-navbar ul {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .dashboard-navbar ul::-webkit-scrollbar {
    display: none;
  }
  .main-container {
    margin-left: unset;
  }
  .manage-dashboard {
    position: relative;
  }
  .navbar-vertical {
    position: relative;
    left: -250px;
    top: 0;
    z-index: 10;
    border-right: 1px solid rgb(202, 202, 202);
    transition: left 0.3s ease-in-out;
  }
  .display-sm {
    display: block;
  }
  .navbar-vertical.active {
    left: 0;
  }
  .mobile-menu-toggle {
    display: block;
    color: #fff;
    background-color: #212121;
    padding: 10px;
    position: absolute;
    left: -15px;
    border-radius: 0 8px 8px 0;
    top: 10px;
    z-index: 100;
    border: none;
    transition: left 0.3s ease-in-out;
  }
  .mobile-menu-toggle:focus,
  .mobile-menu-toggle:active {
    outline: none;
  }
  .mobile-menu-toggle.active {
    left: 235px;
    color: #fff;
    background-color: #212121;
    border-radius: 0 8px 8px 0;
    padding: 10px;
  }
  .subscription-message-ontopbar {
    text-align: right;
  }
  .dashboard-box {
    margin-bottom: 10px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .app-card {
    width: 47%;
  }
  .main-container {
    margin-left: unset;
  }
  .manage-dashboard {
    position: relative;
  }
  .navbar-vertical {
    position: relative;
    left: -250px;
    top: 0;
    z-index: 10;
    border-right: 1px solid rgb(202, 202, 202);
    transition: left 0.3s ease-in-out;
  }
  .display-sm {
    display: block;
  }
  .navbar-vertical.active {
    left: 0;
  }
  .mobile-menu-toggle {
    display: block;
    color: #fff;
    background-color: #212121;
    padding: 10px;
    position: absolute;
    left: -15px;
    border-radius: 0 8px 8px 0;
    top: 10px;
    z-index: 100;
    border: none;
    transition: left 0.3s ease-in-out;
  }
  .mobile-menu-toggle:active {
    outline: none;
  }
  .mobile-menu-toggle:focus,
  .mobile-menu-toggle:active {
    outline: none;
  }
  .mobile-menu-toggle.active {
    left: 235px;
    color: #fff;
    background-color: #212121;
    border-radius: 0 8px 8px 0;
    padding: 10px;
  }
  .subscription-message-ontopbar {
    text-align: right;
  }
}

.upload-json {
  border: 1px dashed #21212140;
}
.beta-badge {
  position: absolute;
  top: 0;
  right: 2px;
}
.fa-beat {
  animation: fa-beat 5s ease infinite;
}

@keyframes fa-beat {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.25);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.25);
  }
  70% {
    transform: scale(1);
  }
}

/* // Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  ...;
} */

/* // Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */
