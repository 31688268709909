.wp-block-appmaker-productscroller > div > div {
  overflow: auto;
  white-space: nowrap;
  display: flex;
}
.wp-block-appmaker-productscroller .product-scroller {
  padding: 5px;
}
.product-scroller-container {
  min-height: 190px;
  padding-bottom: 10px;
}
.wp-block-appmaker-productscroller > div > div > div {
  width: fit-content;
}
.wp-block-appmaker-productscroller > div > div > div > * {
  width: inherit;
}
.wp-block-appmaker-productscroller .block-list-appender {
  display: block;
  margin: auto;
}
